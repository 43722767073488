<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <ValidationObserver ref="carrForm">
              <form action="#" v-if="vmCareerExamFormData && Object.keys(vmCareerExamFormData).length > 0">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrFieldLabel}}</label>
                      <ValidationProvider
                        name="Career Field Name"
                        rules="required"
                        v-slot="{ errors }">
                        <input
                          v-model="vmCareerExamFormData.carr_field"
                          type="text"
                          class="form-control"
                          required
                          disabled/>
                          <span class="text-danger"> {{ errors[0] }} </span>
                          </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_exam_name">
                    {{cvCarrExamNameLabel}}</label>
                    <ValidationProvider
                        name="Career Exam Name"
                        rules="required"
                        v-slot="{ errors }">
                      <input
                      v-model="vmCareerExamFormData.carr_exam_name"
                      type="text"
                      class="form-control"
                      required/>
                      <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_desc">
                      {{cvCarrExamDescLabel}}</label>
                    <input
                      v-model="vmCareerExamFormData.carr_exam_desc"
                      type="text"
                      class="form-control"
                      required/>
                  </div><div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_for">
                      {{cvCarrExamForLabel}}</label>
                    <ValidationProvider
                      name="Career Exam For"
                      rules="required"
                      v-slot="{ errors }">
                      <input
                      v-model="vmCareerExamFormData.carr_exam_for"
                      type="text"
                      class="form-control"
                      required/>
                    <span class="text-danger" mt-1> {{ errors[0] }} </span>
                  </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_weblink">
                      {{cvCarrExamWeblinkLabel}}</label>
                      <ValidationProvider
                      name="Career Exam Weblink"
                      rules="required"
                      v-slot="{ errors }">
                    <input
                      v-model="vmCareerExamFormData.carr_exam_weblink"
                      type="text"
                      class="form-control"
                      required/>
                      <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_exam_country">
                      {{cvCarrExamCountryLabel}}</label>
                    <ValidationProvider
                    name="Career Exam Country"
                    rules="required"
                    v-slot="{ errors }">
                    <input
                      v-model="vmCareerExamFormData.carr_exam_country"
                      type="text"
                      class="form-control"
                      required/>
                    <span class="text-danger" mt-1> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row spinner_add">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary" @click="careerExamEdit()">
                    {{cvSubmitBtn}}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { CareerExams } from "../../../FackApi/api/careerExam"
import ApiResponse from "../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "CareerExamEdit",
  components: {
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCareerExamObj: {
      type: Object,
      default: function () {
        return {
          "carr_field": "",
          "carr_exam_name": "",
          "carr_exam_desc": "",
          "carr_exam_for": "",
          "carr_exam_weblink": "",
          "carr_exam_country": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit careerExam",
      cvCardSubHeader: "Edit careerExam ",
      cvSubmitBtn: "Edit",
      cvCarrFieldLabel: "career",
      cvCarrExamNameLabel: "carr exam name",
      cvCarrExamDescLabel: "carr exam desc",
      cvCarrExamForLabel: "carr exam for",
      cvCarrExamWeblinkLabel: "carr exam weblink",
      cvCarrExamCountryLabel: "carr exam country",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "CareerExam",
      vmCareerExamFormData: {}
    }
  },
  mounted () {
    this.careerExamView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerExamFormData) {
          if (!this.vmCareerExamFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * careerExamView
     */
    async careerExamView () {
      try {
        if (this.propOpenedInModal) {
          this.vmCareerExamFormData = this.propCareerExamObj
        }
        else {
          let careerExamId = this.$route.params.carr_exam_id
          let careerExamViewResp = await CareerExams.careerExamView(this, careerExamId)
          if (careerExamViewResp && careerExamViewResp.resp_status) {
            this.vmCareerExamFormData = careerExamViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at careerExamView() and Exception:", err.message)
      }
    },
    /**
     * careerExamEdit
     */
    async careerExamEdit () {
      let valid = await this.$refs.carrForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        let careerExamAddResp = await CareerExams.careerExamEdit(this, this.vmCareerExamFormData)
        await ApiResponse.responseMessageDisplay(this, careerExamAddResp)
        if (careerExamAddResp && !careerExamAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerExamEditModal", this.vmCareerExamFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at careerExamEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>

/*eslint-disable */
// import CareerExamModel from '../../Model/CareerExam'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let CareerExams = {
  /**
   * careerExamList
   */
  async careerExamList (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "careerExam_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerExamList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * careerExamView
   */
  async careerExamView (context, careerExamId) {
    try {
      
      let post_data = new FormData();
      post_data.append('carr_exam_id', careerExamId);
      return await request.curl(context, "careerExam_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at careerExamView() and Exception:",err.message)
    }
    
  },

  /**
   * careerExamAdd
   */
  async careerExamAdd (context, careerExamObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerExamObj) {
      post_data.append(key, careerExamObj[key]);
    }

    return await request.curl(context, "careerExam_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerExamAdd() and Exception:',err.message)
    }
  },

  /**
   * careerExamEdit
   */
  async careerExamEdit (context, careerExamObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerExamObj) {
      post_data.append(key, careerExamObj[key]);
    }

    return await request.curl(context, "careerExam_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerExamEdit() and Exception:',err.message)
    }
  },

  /**
   * careerExamDelete
   */
  async careerExamDelete (context, careerExamId) {
    try{
    let post_data = new FormData();
    
    post_data.append('carr_exam_id', careerExamId);

    return await request.curl(context, "careerExam_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at careerExamDelete() and Exception:',err.message)
    }
  }
}

export {
  CareerExams
}
